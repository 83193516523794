body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

    --grey-100: #e4e9f0;
    --grey-200: #cfd7e3;
    --grey-300: #b5c0cd;
    --grey-800: #3e4e63;
    --grid-gap: 2px;
    --day-label-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  height: 100%;
}

.day-of-week,
.calendar-days-grid {
  /* 7 equal columns for weekdays and days cells */
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 100%;
}

.calendar-days-grid {
  height: 100%;
  position: relative;
  /* Show border between the days */
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  border-top: solid 1px var(--grey-200);
}

.calendar-day {
  position: relative;
  min-height: 100px;
  font-size: 16px;
 /* background-color: #fff;*/
  color: var(--grey-800);
  padding: 5px;
  list-style-position:inside;
  border: 1px solid black;
}

.calendar-day-selected {
  flex-direction:column;
  position: relative;
  min-height: 100px;
  font-size: 16px;
 /* background-color: #ccc;*/
  color: var(--grey-800);
  padding: 5px;
  list-style-position:inside;
  border: 3px solid black;
}

/* Position the day label within the day cell */
.calendar-day-date {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  right: 2px;
  border: 3px;
/*  width: var(--day-label-size);
  height: var(--day-label-size);*/
}

.calendar-day-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2px;
  border: 3px;
 /* width: var(200px));
  height: var(50px);*/
}

li{
  list-style: none;
}

.signOnForm label {
  display: inline-block;
  width: 100px;
}

.history-form-left{
  width: 45%;
}

.history-form-right{
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.history-form-right img {
  max-width: 200px;
  max-height: 200px;
}

.history-form-container{
  width: 100%;
  display: flex;
}

.history-form-container .select-label {
  width: 150px;
  display: inline-block;
  font-size: 18px;
}

.history-form-container select{
  min-width: 100px;
  max-width: 150px;
  font-size: 18px;
}

.history-form-button-bar{
  display: inline-block;
  width: 100%;
}

.history-form-button-bar input {
  width: 50%;
  min-width: 150px;
  min-height: 50px;
  height: 50px;
  font-size: 20px;
}

.history-form-button-bar .save {
  background-color: lightgreen;
}

.history-form-button-bar .clear {
  background-color: lightpink;
}


.injection-app-header {
  display: flex;
  justify-content: space-between;
  background-color: grey;
  color: white;
  font-size: 14px;
  width: 100%;
}

.injection-app-header label {
  display: inline-block;
  width: 100px;
}

.verify-code label {
  display: inline-block;
  width: 100px;
}

.register-new-user label {
  display: inline-block;
  width: 150px;
}

.data-loading {
  display: flex;
  vertical-align: middle;
  align-content: space-around;
}

.date-picker {
  display: flex;
}
